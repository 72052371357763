<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">
            {{ readOnly ? $t("view_iot_device") : $t("edit") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="iotDevice.name"
                  dense
                  :disabled="readOnly"
                  :label="$t('name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="iotDevice.iot_device_make_id"
                  dense
                  :items="iotDeviceMakesList"
                  item-text="name"
                  item-value="id"
                  :label="$t('manufacturer_name')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="iotDevice.iot_device_model_id"
                  dense
                  :items="iotDeviceModelsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('model_name_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="iotDevice.data_sim_id"
                  dense
                  :items="dataSimsList"
                  item-text="msisdn"
                  item-value="id"
                  :label="$t('data_sim_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="iotDevice.imei"
                  dense
                  :label="$t('imei_*')"
                  :disabled="readOnly"
                  :rules="[
                    rules.required,
                    rules.number,
                    rules.min_length(15),
                    rules.max_length(15),
                  ]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="iotDevice.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :disabled="readOnly || iotDevice.tenant_id"
                  :rules="[rules.required]"
                />
              </v-col>
              <!-- Device assigment -->
              <template>
                <v-col
                  v-if="iotDevice.is_active == true"
                  cols="12"
                  class="py-0"
                >
                  <span class="text-h3">{{ $t("device_assignments") }}</span>
                </v-col>
                <v-col
                  v-if="iotDevice.is_active == true"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="iotDevice.tenant_id"
                    dense
                    :items="tenantList.filter((r) => r.product != 'fleet')"
                    item-value="id"
                    item-text="name"
                    :label="$t('select_tenant')"
                    :disabled="readOnly"
                    clearable
                    @change="setFlespiConfig"
                  />
                </v-col>
              </template>
              <!-- Device assigment -->
            </v-row>
            <!-- flespip config -->
            <v-row v-if="iotDevice.tenant_id">
              <v-col
                cols="12"
                class="py-0"
              >
                <span class="text-h3">{{ $t("flespi_configurations") }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="iotDevice.flespi_plugin_id"
                  dense
                  :items="pluginsList"
                  :loading="pluginLoading"
                  item-value="flespi_plugin_id"
                  item-text="name"
                  multiple
                  :label="$t('plugin')"
                  :disabled="readOnly"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="iotDevice.flespi_calc_id"
                  dense
                  :loading="calcLoading"
                  :items="calcsList"
                  item-value="flespi_calc_id"
                  item-text="name"
                  multiple
                  :label="$t('calc')"
                  :disabled="readOnly"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="iotDevice.flespi_stream_id"
                  dense
                  :loading="streamLoading"
                  :items="streamsList"
                  item-value="flespi_stream_id"
                  item-text="name"
                  multiple
                  :label="$t('stream')"
                  :disabled="readOnly"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="iotDevice.flespi_device_id"
                  dense
                  :label="$t('device_id_*')"
                  disabled
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <!-- flespip config -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="closeModal()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="!readOnly"
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(iotDevice)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    iotDevice: {
      type: Object,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      iotDeviceMakes: [],
      stream: [],
      plugin: [],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      iotDeviceMakesList: "iotDeviceMakes/iotDeviceMakesList",
      iotDeviceModelsList: "iotDeviceModels/listModelsByType",
      dataSimsList: "dataSims/activeListDataSims",
      // oemClinetList: "oemclients/getOemsList",
      tenantList: "tenantsManagement/getTenantsList",
      //
      streamLoading: "flespiStream/getIsLoading",
      streamsList: "flespiStream/getStreamsList",
      pluginLoading: "flespiPlugin/getIsLoading",
      pluginsList: "flespiPlugin/getPluginsList",
      calcLoading: "flespiCalculator/getIsLoading",
      calcsList: "flespiCalculator/getCalcsList",
    }),
  },

  watch: {
    "iotDevice.iot_device_make_id": {
      handler: async function (value) {
        this.$store.dispatch("iotDeviceModels/getModelsByType", value);
      },
    },
    "iotDevice.is_active": {
      handler: async function (value) {
        if (value == false) {
          this.iotDevice.tenant_id = null;
        }
      },
    },
    isVisibleDialog: {
      async handler(newValue) {
        if (newValue) {
          await this.$store.dispatch("dataSims/list", {
            editIotDevices: this.iotDevice.data_sim_id,
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("iotDeviceModels/list");
    if (this.iotDevice.tenant_id) {
      this.callFlespiConfig(this.iotDevice.tenant_id);
    }
    // this.$store.dispatch("tenantsManagement/list");
    this.$store.dispatch("tenantsManagement/list");
    if (this.readOnly) {
      this.setDataforReadOnly();
      this.$store.dispatch("flespiCalculator/list");
    } else {
      let id = this.iotDevice.iot_device_make_id;
      this.$store.dispatch("iotDeviceModels/getModelsByType", id);
    }
  },
  methods: {
    async updateItem(iotDevice) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = { iotDevice, filter: { ...this.filterOptions } };

        await this.$store
          .dispatch("iotDevices/update", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    setDataforReadOnly() {
      setTimeout(async () => {
        let id = this.iotDevice.iot_device_make_id;
        await this.$store.dispatch("iotDeviceMakes/list");
        await this.$store.dispatch("iotDeviceModels/getModelsByType", id);
      }, 0);
    },
    setFlespiConfig(val) {
      // remove key Stream, Plugin and Calc
      delete this.iotDevice.flespi_stream_id;
      delete this.iotDevice.flespi_plugin_id;
      delete this.iotDevice.flespi_calc_id;
      if (val) {
        this.callFlespiConfig(val);
      }
    },
    async callFlespiConfig(tenant_id) {
      await this.$store.dispatch("flespiStream/list", { tenant_id });
      await this.$store.dispatch("flespiPlugin/list", { tenant_id });
      await this.$store.dispatch("flespiCalculator/list", { tenant_id });
    },
    closeModal() {
      this.callFlespiConfig();
      this.toggleEdit();
    },
  },
};
</script>
